import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tickets } from '../models/client/tickets';
import { ResTickets } from '../models/response/res_tickets';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private http: HttpClient) { }
  private URL_TICKETS = 'ticket';

  saveAll(ticketsTarifas: Tickets[][], rsvId: number): Promise<boolean> {
    const paramTickets = ticketsTarifas.map((tickets => tickets.map(ticket => new ResTickets(ticket.ticId, rsvId, ticket.ticNombre, ticket.ticApellidos, ticket.ticTipoDoc, ticket.ticDoc, undefined, false, false, ticket.ticTarifaNombre, ticket.ticTarifaPrecio))));
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_TICKETS + '/saveAll/', paramTickets)
      .subscribe((res: boolean) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }

  GenerateQRTicketsReserva(idReserva: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_TICKETS + '/generateQRTicketsReserva/' + idReserva)
      .subscribe((res: boolean) => {
        resolve(res);
      },
      (error: boolean) => {
        reject(error);
      });
    });  }
}
