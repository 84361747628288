import { Paquete } from "./paquete";
import { Tarifa } from "./tarifa";

export class Suplemento {

    supId: number;
    supDescripcion: string;
    supNombre: string;
    supOcupaPlaza: boolean;
    supActivo: boolean;
    supTarifa: Tarifa;
    supPaquetes: Array<Paquete>;
    supTipo: number;

    constructor($supId: number, $supDescripcion: string, $supNombre: string,
        $supOcupaPlaza: boolean, $supActivo: boolean, $supTarifa: Tarifa, $supPaquetes: Array<Paquete>, $supTipo: number) {
        this.supId = $supId;
        this.supDescripcion = $supDescripcion;
        this.supNombre = $supNombre;
        this.supOcupaPlaza = $supOcupaPlaza;
        this.supActivo = $supActivo;
        this.supTarifa = $supTarifa;
        this.supPaquetes = $supPaquetes;
        this.supTipo = $supTipo;
    }
}

