export class Tickets {
  ticId: number;
  ticRsvId: number;
  ticNombre: string;
  ticApellidos: string;
  ticTipoDoc: string;
  ticDoc: string;
  ticQr: string;
  ticFichadoIda: boolean;
  ticFichadoVuelta: boolean;
  ticTarifaNombre: string;
  ticTarifaPrecio: number;

  constructor(ticId?: number, ticRsvId?: number, ticNombre?: string, ticApellidos?: string, ticTipoDoc?: string, ticDoc?: string, ticQr?: string, ticFichadoIda?: boolean, ticFichadoVuelta?: boolean, ticTarifaNombre?: string, ticTarifaPrecio?: number) {
    this.ticId = ticId;
    this.ticRsvId = ticRsvId;
    this.ticNombre = ticNombre;
    this.ticApellidos = ticApellidos;
    this.ticTipoDoc = ticTipoDoc;
    this.ticDoc = ticDoc;
    this.ticQr = ticQr;
    this.ticFichadoIda = ticFichadoIda;
    this.ticFichadoVuelta = ticFichadoVuelta;
    this.ticTarifaNombre = ticTarifaNombre;
    this.ticTarifaPrecio = ticTarifaPrecio;
  }
}
