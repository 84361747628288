<div class="payment">
  <div class="title">
    <h2>{{paquete.paqDescripcion}}</h2>
  </div>
  <ng-container *ngIf="totalPrecio > 0">
    <ng-container *ngIf="countTickets > 0 && quiereGestionAutorizacion">
      <div class="gestion bgSoft">
        <h3 i18n="Passanger">Gastos de gestión <mat-icon aria-hidden="false">arrow_forward</mat-icon> {{countTickets}} X {{autorizacion.supTarifa.tarPrecio}}€</h3>
      </div>
    </ng-container>
    <ng-container *ngIf="!emptyTickets">
      <div class="pasajero bgSoft">
        <h3 i18n="Passanger">Pasajeros</h3>
      </div>
      <ng-container *ngFor="let tarifa of selectedTicketsEachTarifa">
        <div class="enum" *ngIf="tarifa.cantidad !== 0">
          <p>{{tarifa.nombre}} <mat-icon aria-hidden="false">arrow_forward</mat-icon> {{tarifa.cantidad}} X {{tarifa.precio}}€</p>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!emptySuplementos">
      <div class="suplemento bgSoft">
        <h3 i18n="Passanger">Equipamiento Extra</h3>
      </div>
      <ng-container *ngFor="let suplemento of selectedSuplementos">
        <div class="enum" *ngIf="suplemento.cantidad !== 0">
          <p>{{suplemento.nombre}} <mat-icon aria-hidden="false">arrow_forward</mat-icon> {{suplemento.cantidad}} X {{suplemento.precio}}€</p>
        </div>
      </ng-container>
    </ng-container>

    <ng-container>
      <div class="cupon bgSoft">
        <h3 i18n="PromotionalCode" style='text-decoration: underline;' (click)="showCodigo()">Código promocional</h3>
      </div>
    </ng-container>

    <ng-container *ngIf="isShowCodigo">
        <mat-form-field class="cupon-width" appearance="fill">
          <mat-label i18n="Cupon">Cupón</mat-label>
          <input class="input-cupon" autocomplete="off" maxlength="9" minlength="6" required matInput [(ngModel)]="codigoCupon" [ngModelOptions]="{standalone: true}" disabled="{{opcionDisabled}}">
        </mat-form-field>
        <button i18n="Aplicar" mat-button [disabled]="codigoCupon === ''" (click)="comprobarCupon()" class="btn btn-secondary btn-sm" disabled="{{opcionDisabled}}">Aplicar</button>
        
        <div>
          <ng-container *ngIf='cupon'>
            <span i18n="Aplicado" class='cupon-info'>{{cupon.cupCodigo}} aplicado</span>
          </ng-container>
        </div>
        
    </ng-container>

    <div class="precio bgSoft">
      <h2><span i18n="Amount">TOTAL</span> {{totalPrecio}}€</h2>
      <h2 *ngIf='cupon'><span i18n="Discount">DESCUENTO</span> {{totalDescuento.toFixed(2)}}€</h2>
      <h1 *ngIf='cupon'><span i18n="AmountToPay">TOTAL A PAGAR</span> {{totalPagar.toFixed(2)}}€</h1>
    </div>
  </ng-container>
  <div class="tarjetas">
    <p i18n="SafePayment">PAGO SEGURO</p> 
    <p>
        <img src="/assets/img/visa.png" alt="visa" class="d-inline-block mx-1"> 
        <img src="/assets/img/mastercard.png" alt="mastercard" class="d-inline-block mx-1"> 
        <img src="/assets/img/americanexpress.png" alt="american express" class="d-inline-block mx-1">
    </p>
  </div> 
</div> 
<button [disabled]="!someAdulto || limitReached" (click)="comprarTickets()" class="btn btn-secondary btn-block btn-lg btn-cart" i18n="BuyTickets">
    COMPRAR TICKETS
</button>