import { ResTickets } from './res_tickets';

export class ResReservas {
  rsvId: number;
  rsvCorreo: string;
  rsvInfoTransaccion: string;
  rsvPagado: boolean;
  rsvTelefono: string;
  rsvImporte: number;
  rsvSrvInicio: number;
  rsvSrvFin: number;
  rsvPlazasOcupadas: number;
  rsvBodegaOcupadas: number;
  rsvGeneralOcupadas: number;
  rsvInfoSuplementos: string;
  rsvObservaciones: string;
  rsvFechaInicioTransaccion: Date;
  rsvCancelado: boolean;
  tickets: ResTickets[];
  rsvIdioma: string;
  rsvPaqId: number;
  rsvCupCodigo: string;
  rsvLocalizador: string;

  constructor(rsvId?: number, rsvCorreo?: string, rsvInfoTransaccion?: string, rsvPagado?: boolean, rsvTelefono?: string, rsvImporte?: number, rsvSrvInicio?: number, rsvSrvFin?: number, rsvPlazasOcupadas?: number, rsvBodegaOcupadas?: number, rsvGeneralOcupadas?: number, rsvInfoSuplementos?: string, rsvObservaciones?: string, rsvFechaInicioTransaccion?: Date, rsvCancelado?: boolean, tickets?: ResTickets[], rsvIdioma?: string, rsvCupCodigo?: string, rsvPaqId?: number, rsvLocalizador = '') {
    this.rsvId = rsvId;
    this.rsvCorreo = rsvCorreo;
    this.rsvInfoTransaccion = rsvInfoTransaccion;
    this.rsvPagado = rsvPagado;
    this.rsvTelefono = rsvTelefono;
    this.rsvImporte = rsvImporte;
    this.rsvSrvInicio = rsvSrvInicio;
    this.rsvSrvFin = rsvSrvFin;
    this.rsvPlazasOcupadas = rsvPlazasOcupadas;
    this.rsvBodegaOcupadas = rsvBodegaOcupadas;
    this.rsvGeneralOcupadas = rsvGeneralOcupadas;
    this.rsvInfoSuplementos = rsvInfoSuplementos;
    this.rsvObservaciones = rsvObservaciones;
    this.rsvFechaInicioTransaccion = rsvFechaInicioTransaccion;
    this.rsvCancelado = rsvCancelado;
    this.tickets = tickets;
    this.rsvIdioma = rsvIdioma;
    this.rsvPaqId = rsvPaqId;
    this.rsvCupCodigo = rsvCupCodigo;
    this.rsvLocalizador = rsvLocalizador;
  }
  public generateLocalizador(): void {
    const dic = 'QWERTYUIOPASDFGHJKLZXCVVBNM0123456789';
    let localizador = '';
    let index = 0;
    for (let i = 0; i < 8; i++) {
      index = Math.round(Math.random() * (dic.length - 1));
      localizador = localizador.concat(dic[index]);
    }
    this.rsvLocalizador = localizador;
}
}
