import { ResPlanning } from './res_planning';
import { ResUnidad } from './res_unidad';
export class ResCalendario {
    calId: number;
    calDescripcion: string;
    calFechaInicio: Date;
    calFechaFin: Date;
    calDias: string;
    calPaqId: number;
    calCapacidad: number;
    planningList: Array<ResPlanning>;

    constructor($calId: number, $calDescripcion: string,
        $calFechaInicio: Date, $calFechaFin: Date, $calDias: string, $calPaqId: number, $planningList: Array<ResPlanning>, $calCapacidad: number) {
        this.calId = $calId;
        this.calDescripcion = $calDescripcion;
        this.calFechaInicio = $calFechaInicio;
        this.calFechaFin = $calFechaFin;
        this.calDias = $calDias;
        this.calPaqId = $calPaqId;
        this.calCapacidad = $calCapacidad;
        this.planningList = $planningList;
    }
}