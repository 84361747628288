import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  loading = false;

  constructor() { }

  hourToUnixTime(hour?: string): number {
    if (!hour) {
      const date = new Date();
      hour = date.getHours().toString() + ':' + date.getMinutes().toString();
    }
    const horSplit = hour.split(':').map(x => parseInt(x, 10));
    const horUnix = new Date (1970, 0, 1, horSplit[0], horSplit[1]).getTime();
    return horUnix;
  }

}
