import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourPipe'
})
export class HourPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const arrValue = value.split(':');
    return arrValue[0] + ':' + arrValue[1];
  }

}
