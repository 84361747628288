import { ResExcepcion } from './../models/response/res_excepcion';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResCupon } from '../models/response/res_cupon';

@Injectable({
  providedIn: 'root'
})
export class CuponService {

  constructor(private http: HttpClient) { }

    private URL_CUPON = 'cupon';

    public checkCupon(codigoCupon : String, fecha: String, paqueteId : Number): Promise<ResCupon> {
      return new Promise<ResCupon>((resolve, reject) => {
        this.http.get(environment.backend_public + this.URL_CUPON + '/checkCupon?codigo-cupon=' + codigoCupon + '&fecha=' + fecha + '&paquete-id=' + paqueteId)
      .subscribe((res: any) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }
}
