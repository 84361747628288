<ng-container>
  <h1 i18n="aviso-legal" class="titulo">Aviso Legal</h1>
  <div class="casilla-privacidad">
    <mat-checkbox [(ngModel)]="respuestaPrivacidad"><span i18n>He leído y acepto la </span><a target="_blank" i18n [href]="privacidad_url">política de privacidad.</a></mat-checkbox>
  </div>
  <div class="casilla-condiciones">
    <mat-checkbox [(ngModel)]="respuestaCodiciones"><span i18n>He leído y acepto los </span><a target="_blank" i18n [href]="condiciones_url">términos y condiciones.</a></mat-checkbox>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="continuar()" i18n>Continuar</button>
  </div>
</ng-container>