import { ResSuplemento } from './../../models/response/res_suplemento';
import { Suplemento } from './../../models/client/suplemento';
import { Paquete } from './../../models/client/paquete';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResCupon } from './../../models/response/res_cupon';
import { CuponService } from './../../services/cupon.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-resumenPago',
  templateUrl: './resumenPago.component.html',
  styleUrls: ['./resumenPago.component.scss']
})
export class ResumenPagoComponent implements OnInit {

  @Input() paquete: Paquete;
  @Input() autorizacion: Suplemento;
  @Input() quiereGestionAutorizacion: boolean;
  @Output() eventComprarTickets: EventEmitter<any> = new EventEmitter();
  selectedSuplementos = new Array<{nombre: string, precio: number, cantidad: number, tipo: number}>();
  selectedTicketsEachTarifa = new Array<{nombre: string, precio: number, cantidad: number}>();
  totalPrecio = 0;
  totalDescuento = 0;
  totalPagar = 0;
  emptyTickets = true;
  emptySuplementos = true;
  countTickets: number;
  cupon: ResCupon;
  codigoCupon: string;
  fechaReserva: string;
  paqueteId: Number;
  isShowCodigo: Boolean = false;
  opcionDisabled: Boolean = false;
  someAdulto: boolean;
  limitReached = false;

  constructor(private cuponService: CuponService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  showCodigo() {
    this.isShowCodigo = true;
  }

  setFechaReserva(fecha : string){
    this.fechaReserva = fecha;
  }

  setPaqueteId(paqueteId : Number){
    this.paqueteId = paqueteId;
  }

  setSelectedTicketsEachTarifa(selectedTicketsEachTarifa: { nombre: string; precio: number; cantidad: number; }[]): void {
    this.selectedTicketsEachTarifa = selectedTicketsEachTarifa;
    this.emptyTickets = selectedTicketsEachTarifa.every(x => x.cantidad === 0);
    this.setGastosGestion(this.quiereGestionAutorizacion);
  }

  setSelectedSuplementos(selectedSuplementos: { nombre: string; precio: number; cantidad: number; tipo: number}[]): void {
    this.selectedSuplementos = selectedSuplementos;
    this.emptySuplementos = selectedSuplementos.every(x => x.cantidad === 0);
    this.calculateTotalPrecio();
  }

  setGastosGestion(quiereGestionAutorizacion: any): void {
    this.countTickets = this.selectedTicketsEachTarifa.filter(x => x.precio > 0).map(x => x.cantidad).reduce((a, b) => a + b);
    this.quiereGestionAutorizacion = quiereGestionAutorizacion;
    this.calculateTotalPrecio();
  }

  comprarTickets(): void {
    this.eventComprarTickets.emit({precio: this.totalPagar, selectedTicketsEachTarifa: this.selectedTicketsEachTarifa, selectedSuplementos: this.selectedSuplementos, cupCodigo: this.codigoCupon ? this.codigoCupon.toUpperCase() : undefined });
  }

  comprobarCupon(): void {
    this.opcionDisabled = false;
    this.cuponService.checkCupon(this.codigoCupon, this.fechaReserva, this.paquete.paqId )
    .then((res) => {
      this.cupon = res;
      this.calculateTotalPrecio();
      this.opcionDisabled = true;
    })
    .catch(err => {
      this.codigoCupon = undefined;
      this.cupon = undefined;
      if(err.status === 404) {
        this.snackBar.open($localize`Cupón no encontrado`, 'OK');
      } else if(err.status === 417) {
        this.snackBar.open($localize`Cupón no válido`, 'OK');
      } else if(err.status === 412) {
        this.snackBar.open($localize`Cupón agotado`, 'OK');
      } else {
        this.snackBar.open($localize`El servicio actualmente no está disponible, inténtelo más tarde`, 'OK');
      }
    });
  }

  setLimitReached(limitReached: boolean): void {
    this.limitReached = limitReached;
  }

  private calculateTotalPrecio(): void {
    let tmpTotalPrecio = 0;
    let tmpDescuento = 0;

    this.selectedTicketsEachTarifa.forEach(selectedTickes => {
      if(this.cupon) {
        if(!this.cupon.cupEsPorcentaje) {
          if (this.cupon.cupCuota > selectedTickes.precio) {
            tmpDescuento += selectedTickes.precio * selectedTickes.cantidad;
          } else {
            tmpDescuento += this.cupon.cupCuota * selectedTickes.cantidad;
          }
          tmpTotalPrecio += selectedTickes.precio * selectedTickes.cantidad;
        } else {
          tmpTotalPrecio += (selectedTickes.precio * selectedTickes.cantidad);
          tmpDescuento += (selectedTickes.precio * selectedTickes.cantidad) * (this.cupon.cupCuota/100);
        }
      } else {
        tmpTotalPrecio += selectedTickes.precio * selectedTickes.cantidad;
      }
    });

    this.someAdulto = tmpTotalPrecio > 0;

    this.selectedSuplementos.forEach(suplemento => {
      tmpTotalPrecio += suplemento.precio * suplemento.cantidad;
    });

    if (this.quiereGestionAutorizacion){
      tmpTotalPrecio += this.countTickets * this.autorizacion.supTarifa.tarPrecio;
    }

    this.totalDescuento = tmpDescuento;
    this.totalPrecio = tmpTotalPrecio;
    this.totalPagar = this.totalPrecio - this.totalDescuento;
  }
}
