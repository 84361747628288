import { IssueComponent } from './pages/paymentResult/issue/issue.component';
import { SuccessComponent } from './pages/paymentResult/success/success.component';
import { SelectServicioComponent } from './pages/selectServicio/selectServicio.component';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: SelectServicioComponent
  },
  {
    path: 'success',
    component: SuccessComponent
  },
  {
    path: 'issue',
    component: IssueComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
