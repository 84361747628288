import { ResSuplemento } from './../../models/response/res_suplemento';
import { ResumenPagoComponent } from './../resumenPago/resumenPago.component';
import { Tickets } from './../../models/client/tickets';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ResPaquete } from 'src/app/models/response/res_paquete';
import * as $ from 'jquery';

@Component({
  selector: 'app-selectPasajeros',
  templateUrl: './selectPasajeros.component.html',
  styleUrls: ['./selectPasajeros.component.scss']
})
export class SelectPasajerosComponent implements OnInit {
form: HTMLFormElement;
  colSizeTarifas: number;

@ViewChild('form') set content(content: HTMLFormElement) {
  if (content as HTMLFormElement) { // initially setter gets called with undefined
      this.form = content;
  }
}
  @Input() paquete: ResPaquete;
  @Input() listaSuplementos: ResSuplemento[];
  @Output() updateTarifas: EventEmitter<any> = new EventEmitter();
  @Output() updateSuplementos: EventEmitter<any> = new EventEmitter();
  loading = true;
  tiposDocs = [{value: 'DNI', text: 'DNI'}, {value: 'NIE', text: 'NIE'}, {value: 'Pasaporte', text: $localize`Pasaporte`}]
  sizePasajerosTarifa = new Array(21);
  selectedTicketsEachTarifa = new Array<{nombre: string, precio: number, cantidad: number}>();
  selectedSuplementos = new Array<{nombre: string, precio: number, cantidad: number, tipo: number}>();
  ticketsTarifas: Array<Tickets[]>;
  colSize: number;
  showInfoPasajeros: boolean;
  correo: string;
  confirmacionCorreo: string;
  telefono: string;
  constructor() { }

  ngOnInit(): void {
    this.colSize = (window.innerWidth <= 550 * 1.5) ? 1 : 2;
    this.colSizeTarifas = (window.innerWidth <= 550 * 1.5) ? 1 : 3;

    this.paquete.tarifas.forEach(tarifa => {
      this.selectedTicketsEachTarifa.push({nombre: tarifa.tarNombre, precio: tarifa.tarPrecio, cantidad: 0});
    });
    if (this.listaSuplementos && this.listaSuplementos?.length > 0) {
      this.listaSuplementos.forEach(suplemento => {
        this.selectedSuplementos.push({nombre: suplemento.supNombre, precio: suplemento.supTarifa.tarPrecio, cantidad: 0, tipo: suplemento.supTipo});
      });
    }
    this.ticketsTarifas = Array<Tickets[]>(this.paquete.tarifas.length);
  }

  onSelectCantidadTarifa(indexTarifa: number, cantidad: number): void {
    if (!this.ticketsTarifas[indexTarifa]) {
      this.ticketsTarifas[indexTarifa] = new Array<Tickets>();
    }
    if (cantidad > this.ticketsTarifas[indexTarifa].length){
      const iterations = cantidad  - this.ticketsTarifas[indexTarifa].length;
      for (let index = 0; index < iterations; index++) {
        const ticket = new Tickets();
        ticket.ticTipoDoc = 'DNI';
        ticket.ticTarifaNombre = this.selectedTicketsEachTarifa[indexTarifa].nombre;
        ticket.ticTarifaPrecio = this.selectedTicketsEachTarifa[indexTarifa].precio;
        if (ticket.ticTarifaPrecio === 0) {
          ticket.ticNombre = '';
          ticket.ticApellidos = '';
          ticket.ticDoc = '';
        }
        this.ticketsTarifas[indexTarifa].push(ticket);
      }
    } else {
      this.ticketsTarifas[indexTarifa].splice(cantidad);
    }
    this.updateTarifas.emit(this.selectedTicketsEachTarifa);
    this.showInfoPasajeros = this.selectedTicketsEachTarifa.some(x => x.cantidad > 0);
  }

  onSelectCantidadSuplemento(): void {
    this.updateSuplementos.emit(this.selectedSuplementos);
  }

  onResize(event: { target: { innerWidth: number; }; }): void {
    this.colSize = (event.target.innerWidth <= 550 * 1.5) ? 1 : 2;
    this.colSizeTarifas = (event.target.innerWidth <= 550 * 1.5) ? 1 : 3;
  }

  validateInfoPasajeros(): boolean {
    let isValid = true;
    let index = 0;
    let indexFirstFail = 0;
    const formInputList =  new Array(this.form.nativeElement)[0] as HTMLInputElement[];
    while (index < formInputList.length) {
      formInputList[index].click();
      isValid = formInputList[index].validity.valid ? isValid : formInputList[index].validity.valid;
      indexFirstFail = (indexFirstFail === undefined && !isValid) ? index : indexFirstFail;
      index++;
    }
    formInputList[indexFirstFail].click();
    return isValid && this.emailValidation();
  }

  emailValidation(): boolean {
    return this.correo === this.confirmacionCorreo;
  }

}
