<div (window:resize)="onResize($event)">
  <mat-card>
    <h2 class="text-green" i18n="Passanger">Pasajeros</h2>
    <mat-divider></mat-divider><br>
    <mat-grid-list [cols]=colSizeTarifas rowHeight="150px" gutterSize="15px" id="tarifas">
      <mat-grid-tile *ngFor="let tarifa of paquete.tarifas; let indexTarifa = index">
        <mat-grid-tile-header>
          <h3>{{tarifa.tarNombreTraduccion}}</h3>
        </mat-grid-tile-header>
        <mat-form-field appearance="outline">
          <mat-label i18n="Amount">Cantidad</mat-label>
          <mat-select [(ngModel)]="selectedTicketsEachTarifa[indexTarifa].cantidad" (selectionChange)="onSelectCantidadTarifa(indexTarifa,selectedTicketsEachTarifa[indexTarifa].cantidad)">
            <mat-option *ngFor="let x of sizePasajerosTarifa; let i=index" [value]="i">
              {{i}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list><br>
    <ng-container *ngIf="listaSuplementos?.length > 0">
      <mat-divider></mat-divider><br>
      <h2 class="text-green" i18n="Extra Equipment">Equipaje Extra</h2>
      <mat-divider></mat-divider><br>
      <mat-grid-list [cols]=colSizeTarifas rowHeight="150px" gutterSize="15px" id="suplementos">
        <mat-grid-tile *ngFor="let suplemento of listaSuplementos; let indexSuplemento = index">
          <mat-grid-tile-header>
            <h3>{{suplemento.supNombreTraduccion}}</h3>
          </mat-grid-tile-header>
          <mat-form-field appearance="outline">
            <mat-label i18n="Amount">Cantidad</mat-label>
            <mat-select [(ngModel)]="selectedSuplementos[indexSuplemento].cantidad" (selectionChange)="onSelectCantidadSuplemento()">
              <mat-option *ngFor="let x of sizePasajerosTarifa; let i=index" [value]="i">
                {{i}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>
  </mat-card>
  <br>
  <mat-card *ngIf="showInfoPasajeros" class="infoPasajeros">
    <form #form>
      <mat-grid-list [cols]=colSize rowHeight="60px" gutterSize="15px" id="ticket">
        <mat-grid-tile>
          <mat-form-field appearance="outline">
            <mat-label i18n="Mail">Correo</mat-label>
            <input onpaste="return false;" ondrop="return false;" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}" autocomplete="off" required matInput [(ngModel)]="correo" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field appearance="outline">
            <mat-label i18n="Mail">Confirmación Correo</mat-label>
            <input onpaste="return false;" ondrop="return false;" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}" autocomplete="off" required matInput [(ngModel)]="confirmacionCorreo" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field appearance="outline">
            <mat-label i18n="Phone">Teléfono</mat-label>
            <input type="tel" pattern="([0-9]{9})|(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})" autocomplete="off" required matInput [(ngModel)]="telefono" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <br><mat-divider></mat-divider>
      <ng-container *ngFor="let ticketList of ticketsTarifas; let i = index;">
        <ng-container *ngFor="let ticket of ticketList; let indexTarifa = index">
          <ng-container *ngIf="ticket.ticTarifaPrecio > 0">
            <br>
            <h2 class="text-green">{{indexTarifa+1}}º Pasajero {{paquete.tarifas[i].tarNombre}}</h2>
            <mat-grid-list [cols]=colSize (window:resize)="onResize($event)" rowHeight="60px" gutterSize="15px" id="ticket">
              <mat-grid-tile>
                <mat-form-field appearance="outline">
                  <mat-label i18n="Name">Nombre</mat-label>
                  <input autocomplete="off" required matInput [(ngModel)]="ticket.ticNombre" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="outline">
                  <mat-label i18n="Surname">Apellidos</mat-label>
                  <input autocomplete="off" required matInput [(ngModel)]="ticket.ticApellidos" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="outline">
                  <mat-label i18n="DNI">Tipo Documento</mat-label>
                  <mat-select [(ngModel)]="ticket.ticTipoDoc" required [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let tipoDoc of tiposDocs" [value]="tipoDoc.value">
                      {{tipoDoc.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="outline">
                  <mat-label i18n="DNI">Documento {{ticket.ticTipoDoc}}</mat-label>
                  <input *ngIf="ticket.ticTipoDoc !== 'Pasaporte'" pattern="([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])" autocomplete="off" required matInput [(ngModel)]="ticket.ticDoc" [ngModelOptions]="{standalone: true}">
                  <input *ngIf="ticket.ticTipoDoc === 'Pasaporte'" pattern="([a-z]|[A-Z]|[0-9]){3,20}" autocomplete="off" required matInput [(ngModel)]="ticket.ticDoc" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="ticketList && ticketList?.length !== 0">
          <br><mat-divider></mat-divider>
        </ng-container>
      </ng-container>
    </form>
  </mat-card>
</div>