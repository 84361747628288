import { MensajeLegalComponent } from './../mensaje-legal/mensaje-legal.component';
import { ResExcepcion } from './../../models/response/res_excepcion';
import { ExcepcionesService } from './../../services/excepciones.service';
import { UtilsService } from './../../services/utils.service';
import { Tarifa } from './../../models/client/tarifa';
import { ResServicios } from './../../models/response/res_servicios';
import { TicketsService } from './../../services/tickets.service';
import { ReservasService } from './../../services/reservas.service';
import { ServiciosService } from './../../services/servicios.service';
import { PaymentsService } from './../../services/payments.service';
import { environment } from './../../../environments/environment';
import { SuplementosService } from './../../services/suplementos.service';
import { ResSuplemento } from './../../models/response/res_suplemento';
import { SelectPasajerosComponent } from './../selectPasajeros/selectPasajeros.component';
import { ResCalendario } from './../../models/response/res_calendario';
import { CalendariosService } from './../../services/calendarios.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResPaquete } from './../../models/response/res_paquete';
import { PaquetesService } from './../../services/paquetes.service';
import { Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { ResHorario } from 'src/app/models/response/res_horario';
import * as moment from 'moment';
import { ResumenPagoComponent } from '../resumenPago/resumenPago.component';
import { ResReservas } from 'src/app/models/response/res_reservas';
import { MatDialog } from '@angular/material/dialog';
const calendarioDictionaryDays = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
import localeES from '@angular/common/locales/es';
import localeEN from '@angular/common/locales/en';
import localeDE from '@angular/common/locales/de';
import localePL from '@angular/common/locales/pl';
import localeIT from '@angular/common/locales/it';
import localeFR from '@angular/common/locales/fr';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'src/app/shared/CustomDateAdapter';
import { ResLimiteCalendarios } from 'src/app/models/response/res_limiteCalendarios';
import { cloneDeep } from 'lodash-es';

switch (environment.idioma.substring(0,2)) {
  case 'es':
    registerLocaleData(localeES);
    break;
  case 'en':
    registerLocaleData(localeEN);
    break;
  case 'de':
    registerLocaleData(localeDE);
    break;
  case 'pl':
    registerLocaleData(localePL);
    break;
  case 'it':
    registerLocaleData(localeIT);
    break;
  case 'fr':
    registerLocaleData(localeFR);
    break;
  default:
    break;
}

const BODEGA = 2;
const GENERAL = 3;

const TOTALHOURS = 24;
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-selectServicio',
  templateUrl: './selectServicio.component.html',
  styleUrls: ['./selectServicio.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter},
    { provide: LOCALE_ID, useValue: environment.idioma.substring(0,2) }
  ]
})
export class SelectServicioComponent implements OnInit {
  @ViewChild(ResumenPagoComponent) resumenPagoComponent: ResumenPagoComponent;
  @ViewChild(SelectPasajerosComponent) selectPasajerosComponent: SelectPasajerosComponent;

  listaPaquetes: ResPaquete[];
  listaSuplementos: ResSuplemento[];
  serviciosIda: ResServicios[] = [];
  serviciosVuelta: ResServicios[] = [];

  horariosIda: ResHorario[] = [];
  horariosVuelta: ResHorario[] = [];
  availableHorariosIda: ResHorario[];
  availableHorariosVuelta: ResHorario[];

  loading: boolean;
  colSize: number;
  showinfoPasajeros = false;
  
  selectedPaquete: ResPaquete;
  selectedFecha: Date;
  selectedHorarioIda: ResHorario;
  selectedHorarioVuelta: ResHorario;
  calendarioSelectedPaquete: ResCalendario;
  calendarioWeekAvailable: string[];
  diaIncompatible: boolean;
  freeCountPlazasServicioIda: any[];
  freeCountPlazasServicioVuelta: any[];
  
  autorizacionChecked = false;
  autorizacion: ResSuplemento;
  quiereGestionAutorizacion = false;
  radioValue = 0;
  excRestricciones: ResExcepcion[] = []; 
  excRefuerzos: ResExcepcion[] = []; 
  allHorariosIda: ResHorario[];
  allHorariosVuelta: ResHorario[];
  srvIdaPlazasNoAvailable: boolean;
  srvIdaBodegaNoAvailable: boolean;
  srvIdaGeneralNoAvailable: boolean;
  srvVueltaPlazasNoAvailable: boolean;
  srvVueltaBodegaNoAvailable: boolean;
  srvVueltaGeneralNoAvailable: boolean;

  readonly todayNoTime = new Date(new Date().setHours(0, 0, 0, 0));
  limiteCalendario: ResLimiteCalendarios;
  limitReached = false;
  
  constructor(private paquetesService: PaquetesService,
              private suplementosService: SuplementosService,
              private paymentsService: PaymentsService,
              private calendariosService: CalendariosService,
              private serviciosService: ServiciosService,
              private excepcionesService: ExcepcionesService,
              private reservasService: ReservasService,
              private ticketsService: TicketsService,
              private utils: UtilsService,
              private datePipe: DatePipe,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
    this.utils.loading = false;
    moment.locale('es');
    // this.snackBar.open($localize`No ha seleccionado ningún ticket`, 'OK');
  }

  ngOnInit(): void {
    // TODO: CARGAR HORAS DE NUEVO AL CAMBIO DE DÍA SI ESTÁN FILTRADAS POR NO MOSTRAR FECHAS ANTERIORES SI ES EL DÍA DE HOY
    // TODO: NO CREAR NUEVOS SERVICIOS BASADOS EN HORARIOS DE EXC REFUERZOS DE UNIDADES (CAMBIOS EN CONSULTA DE UNIDADES DISPONIBLES ??????)
    this.colSize = (window.innerWidth <= 900) ? (window.innerWidth <= 550) ? 1 : 2 : 4;
    this.paquetesService.getAllPaquetes()
    .then((result) => {
      this.listaPaquetes = result;
    })
    .catch(err => {
      this.snackBar.open($localize`El servicio actualmente no está disponible, inténtelo más tarde`, 'OK');
    });
  }

  obtenerSuplementosPaquete(): void {
    this.suplementosService.getByIdPaquete(this.selectedPaquete.paqId)
    .then((res) => {
      this.autorizacionChecked = !this.selectedPaquete.paqRequiereAutorizacion;
      this.listaSuplementos = res.filter(suplemento => suplemento.supTarifa);
      const indexAutorizacion = this.listaSuplementos.findIndex(x => x.supId === environment.idAutorizacion);
      if (indexAutorizacion !== -1) {
        this.autorizacion = this.listaSuplementos.splice(indexAutorizacion, 1)[0];
      }
    })
    .catch(err => {
      this.snackBar.open($localize`El servicio actualmente no está disponible, inténtelo más tarde`, 'OK');
    });
  }

  getHorarioCalendario(fecha: Date): void {
    this.selectedFecha = fecha;
    const strFechaSeleccionada = this.datePipe.transform(this.selectedFecha, 'yyyy-MM-dd');
    //25.5
    let daysAnticipacion = Math.floor(this.selectedPaquete.paqAnticipacionCompra / TOTALHOURS); //1
    const hoursDecimalAnticipacion = this.selectedPaquete.paqAnticipacionCompra % TOTALHOURS; //1.5
    let hoursAnticipacion = Math.floor(this.selectedPaquete.paqAnticipacionCompra - TOTALHOURS * daysAnticipacion) + new Date().getHours(); // 1
    let minutesAnticipacion = ((this.selectedPaquete.paqAnticipacionCompra - Math.floor(this.selectedPaquete.paqAnticipacionCompra - TOTALHOURS * daysAnticipacion)) % TOTALHOURS) * 60 + new Date().getMinutes();//30
    if (minutesAnticipacion >= 60) {
      hoursAnticipacion++;
      minutesAnticipacion -= 60;
    }

    if (hoursAnticipacion >= 24) {
      daysAnticipacion++;
      hoursAnticipacion -= 24;
    }

    const currentTime = Math.abs(this.todayNoTime.getTime() - this.selectedFecha.getTime());
    const currentHours = currentTime / (1000 * 60 * 60);        
    const currentDaysAnticipacion = Math.floor(currentHours / TOTALHOURS);
    
    const anticipacionMS = new Date(1970, 1, 1, hoursAnticipacion, minutesAnticipacion).getTime();

    if (!this.calendarioIsInRange()){

      this.calendariosService.getCalendarioByPaqueteIdFecha(this.selectedPaquete.paqId, strFechaSeleccionada)
      .then(async (calendario: ResCalendario) => {
        this.limiteCalendario = await this.calendariosService.getLimiteCalendarioByIdCalendarioAndFecha(calendario.calId, strFechaSeleccionada)
        this.calendarioSelectedPaquete = calendario;
        this.calendarioWeekAvailable = calendario.calDias.split(',');
        const allHorarios = calendario.planningList.map((x) => x.horario);
        
        if (daysAnticipacion <= currentDaysAnticipacion){
          this.allHorariosIda = this.allHorariosIda.concat(allHorarios.filter(x => x.tipo === environment.srvIda).sort((a, b) => this.hourIsGreatherThanHour(a.horFechaInicio, b.horFechaInicio)));
          this.allHorariosVuelta = this.allHorariosVuelta.concat(allHorarios.filter(x => x.tipo === environment.srvVuelta).sort((a, b) => this.hourIsGreatherThanHour(a.horFechaInicio, b.horFechaInicio)));
  
          this.allHorariosIda = this.allHorariosIda.filter(this.onlyUnique);
          this.allHorariosVuelta = this.allHorariosVuelta.filter(this.onlyUnique);
  
        } else {
          this.allHorariosIda = [];
          this.allHorariosVuelta = [];
        }
        this.horariosIda = [...this.allHorariosIda];
        this.horariosVuelta = [...this.allHorariosVuelta];
        this.fillAvailableHorarios(anticipacionMS, daysAnticipacion, currentDaysAnticipacion);
      })
      .catch(err => {
        this.snackBar.open($localize`Lo sentimos, ha ocurrido un error. Contacte con nosotros por email para poder ofrecerle una solución`, 'OK');
      });
    }
    else {
      this.fillAvailableHorarios(anticipacionMS, daysAnticipacion, currentDaysAnticipacion);
    }
  }
  
  getDailyExcepciones(): void {
    this.excepcionesService.getExcepcionesByDate(this.datePipe.transform(this.selectedFecha, 'yyyy-MM-dd'), this.calendarioSelectedPaquete.calId)
    .then(async (excepciones: ResExcepcion[]) => {
      if (excepciones.length > 0) {
        this.excRestricciones = excepciones.filter(x => x.exeRestriccion);
        this.excRestricciones ? this.excRestricciones : [];
        
        this.excRefuerzos = excepciones.filter(x => !x.exeRestriccion);
        this.excRefuerzos ? this.excRefuerzos : [];

        if (this.excRefuerzos.length > 0) {
          this.applyRefuerzosCalendario();
          await this.applyRefuerzosUnidades();
        }
        if (this.excRestricciones.length > 0) {
          this.applyRestriccionesCalendario();
        }
        this.allHorariosIda = [...this.availableHorariosIda];
        this.allHorariosVuelta = [...this.availableHorariosVuelta];
      }
    })
    .catch((err) => {
    });
  }
  
  applyRefuerzosCalendario(): void {
    if (this.excRefuerzos.length > 0) {
      this.excRefuerzos.filter(x => x.excCal.length > 0).forEach(refuerzos => {
        if (new Date(refuerzos.exeFechaFin) < this.todayNoTime){
          return;
        }
        if (refuerzos.exeTipo === environment.srvIda) {
          this.availableHorariosIda.push(new ResHorario(-1, 'HORARIO CALENDARIO REFUERZO', refuerzos.exeHoraInicio, refuerzos.exeHoraFin, environment.srvIda, undefined));
        }
        if (refuerzos.exeTipo === environment.srvVuelta) {
          this.availableHorariosVuelta.push(new ResHorario(-1, 'HORARIO CALENDARIO REFUERZO', refuerzos.exeHoraInicio, refuerzos.exeHoraFin, environment.srvVuelta, undefined));
        }
      });
      this.availableHorariosIda = this.availableHorariosIda.sort((a, b) => this.hourIsGreatherThanHour(a.horFechaInicio, b.horFechaInicio));
      this.availableHorariosVuelta = this.availableHorariosVuelta.sort((a, b) => this.hourIsGreatherThanHour(a.horFechaInicio, b.horFechaInicio));
    }
  }
  
  applyRestriccionesCalendario(): void {
    if (this.excRestricciones.length > 0) {
      this.excRestricciones.filter(x => x.excCal.length > 0).forEach(restriccion => {
        if (new Date(restriccion.exeFechaFin) < this.todayNoTime){
          return;
        }
        if (restriccion.exeTipo === environment.srvIda) {
          this.availableHorariosIda = this.availableHorariosIda.filter(horIda => !(this.hourIsGreatherThanHour(horIda.horFechaInicio, restriccion.exeHoraInicio) === 1 && this.hourIsGreatherThanHour(restriccion.exeHoraFin, horIda.horFechaInicio) === 1));
          if (this.availableHorariosIda.length === 0) {
            this.availableHorariosVuelta = [];
            return;
          } else {
            this.availableHorariosVuelta = this.availableHorariosVuelta.filter(horVuelta => this.availableHorariosIda.some(horIda => this.hourIsGreatherThanHour(horVuelta.horFechaInicio, horIda.horFechaInicio) === 1));
            if (this.availableHorariosVuelta.length === 0) {
              this.availableHorariosIda = [];
              return;
            }
          }
        }
        if (restriccion.exeTipo === environment.srvVuelta) {
          this.availableHorariosVuelta = this.availableHorariosVuelta.filter(horVuelta => !(this.hourIsGreatherThanHour(horVuelta.horFechaInicio, restriccion.exeHoraInicio) === 1 && this.hourIsGreatherThanHour(restriccion.exeHoraFin, horVuelta.horFechaInicio) === 1));
          if (this.availableHorariosVuelta.length === 0) {
            this.availableHorariosIda = [];
            return;
          }
        }
      });
    }
  }

  applyRefuerzosUnidades(): Promise<any> {
    if (this.excRefuerzos.length > 0) {
      const excRefuerzosUni = this.excRefuerzos.filter(x => x.excUni.length > 0);
      const selectedFechaOffset = this.selectedFecha;
      selectedFechaOffset.setHours(1);
      return this.serviciosService.checkRefuerzosAndSaveAll(excRefuerzosUni, selectedFechaOffset, this.selectedPaquete.paqDescripcion)
        .then((res) => {
          if (res.length > 0){
            res.forEach(servicio => {
              if (servicio.srvTipo === environment.srvIda) {
                this.serviciosIda.push(servicio);
              }
              if (servicio.srvTipo === environment.srvVuelta) {
                this.serviciosVuelta.push(servicio);
              }
            });
          }
          if (excRefuerzosUni.length > 0) {
            excRefuerzosUni.forEach(refuerzo => {
              if (refuerzo.exeTipo === environment.srvIda) {
                if (!this.horariosIda.some(x => x.horFechaInicio === refuerzo.exeHoraInicio)) {
                  this.availableHorariosIda.push(new ResHorario(-1, 'HORARIO UNIDAD REFUERZO', refuerzo.exeHoraInicio, refuerzo.exeHoraInicio, environment.srvIda, undefined));
                }
              }
              if (refuerzo.exeTipo === environment.srvVuelta) {
                if (!this.horariosVuelta.some(x => x.horFechaInicio === refuerzo.exeHoraInicio)) {
                  this.availableHorariosVuelta.push(new ResHorario(-1, 'HORARIO UNIDAD REFUERZO', refuerzo.exeHoraInicio, refuerzo.exeHoraInicio, environment.srvVuelta, undefined));
                }
              }
            });
          }
          this.availableHorariosIda = this.availableHorariosIda.sort((a, b) => this.hourIsGreatherThanHour(a.horFechaInicio, b.horFechaInicio));
          this.availableHorariosVuelta = this.availableHorariosVuelta.sort((a, b) => this.hourIsGreatherThanHour(a.horFechaInicio, b.horFechaInicio));
        })
        .catch((err) => {
        });
      }
    }

    applyRestriccionesUnidades(): void {
      if (this.excRestricciones.length > 0) {
        this.excRestricciones.filter(x => x.excUni.length > 0).forEach(restriccion => {
        if (restriccion.exeTipo === environment.srvIda && this.serviciosIda.length > 0) {
          this.serviciosIda = this.serviciosIda.filter(servicioIda => !(restriccion.excUni.some(unidad => unidad.uniNombre === servicioIda.srvUnidad) && servicioIda.srvHora >= restriccion.exeHoraInicio && servicioIda.srvHora <= restriccion.exeHoraFin));
          if (this.serviciosIda.length === 0) {
            this.serviciosVuelta = [];
            return;
          }
        }
        if (restriccion.exeTipo === environment.srvVuelta && this.serviciosVuelta.length > 0) {
          this.serviciosVuelta = this.serviciosVuelta.filter(servicioVuelta => !(restriccion.excUni.some(unidad => unidad.uniNombre === servicioVuelta.srvUnidad) && servicioVuelta.srvHora >= restriccion.exeHoraInicio && servicioVuelta.srvHora <= restriccion.exeHoraFin));
          if (this.serviciosVuelta.length === 0) {
            this.serviciosIda = [];
            return;
          }
        }
      });
    }
  }
  
  private filterAvailableCalendar(): void {
    this.diaIncompatible = !this.calendarioWeekAvailable.includes(calendarioDictionaryDays[this.selectedFecha.getDay()]);
    if (this.diaIncompatible) {
      this.snackBar.open($localize`La actividad seleccionada no está disponible para este día`, 'OK', { duration: 10000 });
    }
    else if (this.selectedFecha.getTime() === this.todayNoTime.getTime()) {
      this.filterIdaGreatherThanCurrentHour();
    }
  }
  
  checkServicioAvailable(): void {
    if (this.selectedHorarioIda?.horFechaInicio && this.selectedHorarioVuelta?.horFechaInicio) {
      const promiseArr = new Array<Promise<ResServicios[]>>();
      promiseArr.push(this.serviciosService.getServicioByCalendarioFechaTipo(this.calendarioSelectedPaquete.calId, this.datePipe.transform(this.selectedFecha, 'yyyy-MM-dd'), this.selectedHorarioIda.horFechaInicio, environment.srvIda));
      promiseArr.push(this.serviciosService.getServicioByCalendarioFechaTipo(this.calendarioSelectedPaquete.calId, this.datePipe.transform(this.selectedFecha, 'yyyy-MM-dd'), this.selectedHorarioVuelta.horFechaInicio, environment.srvVuelta));
      Promise.all(promiseArr)
      .then((servicios: Array<ResServicios[]>) => {
        this.serviciosIda = this.serviciosIda.concat(servicios[0]);
        this.serviciosVuelta = this.serviciosVuelta.concat(servicios[1]);
        this.applyRestriccionesUnidades();
      })
      .catch((err) => {
        if (err.status === 404) {
          this.snackBar.open($localize`El servicio actualmente no está disponible, inténtelo más tarde`, 'OK');
        } else {
          this.snackBar.open($localize`No hay disponibilidad para esta fecha/hora. Por favor, seleccione otra.`, 'OK');
        }
      });
    }
  }

  updateTarifas(selectedTicketsEachTarifa: { nombre: string; precio: number; cantidad: number; }[]): void {
    this.resumenPagoComponent.setSelectedTicketsEachTarifa(selectedTicketsEachTarifa);
    this.updateDataForCupon();
    if (this.limiteCalendario) {
      const countTickets = selectedTicketsEachTarifa.map(x => x.cantidad).reduce((a, b) => a + b);
      if (countTickets > this.limiteCalendario.limCalCapacidadLibre) {
        this.limitReached = true;
        this.snackBar.open('No hay suficientes plazas para la experiencia y fecha seleccionados')
      } else {
        this.limitReached = false;
      }
      this.resumenPagoComponent.setLimitReached(this.limitReached);
    }
  }
  
  updateSuplementos($event: any): void {
    this.resumenPagoComponent.setSelectedSuplementos($event);
    this.updateDataForCupon();
  }
  
  updateDataForCupon(){
    this.resumenPagoComponent.setFechaReserva(this.datePipe.transform(this.selectedFecha, 'yyyy-MM-dd'));
    this.resumenPagoComponent.setPaqueteId(this.selectedPaquete.paqId);
  }
  
  async comprarTickets($event: { precio: number; selectedTicketsEachTarifa: { nombre: string; precio: number; cantidad: number; }[], selectedSuplementos: { nombre: string; precio: number; cantidad: number; tipo: number }[], cupCodigo: string }): Promise<void> {
    if (this.limitReached) {
      this.snackBar.open('No hay suficientes plazas para la experiencia y fecha seleccionados')
    } else {
      this.utils.loading = true;
      if (this.selectPasajerosComponent.validateInfoPasajeros()){
        const plazasOcupadas = this.calculatePlazasOcupadas($event);
        const { plazasOcupadasBodega, plazasOcupadasGeneral } = this.calculatePlazasOcupadasSuplementos($event);
        let infoSuplementos: string = $event.selectedSuplementos.filter(x => x.cantidad > 0).map(x => x.cantidad.toString() + ': ' + x.nombre).join(', ');
        if (this.quiereGestionAutorizacion) {
          const separador = infoSuplementos.length > 0 ? ', ' : '';
          infoSuplementos += separador + this.resumenPagoComponent.countTickets + ': ' + environment.autorizacionNombre;
        }
        const servicios = this.getServiciosCreadosForReserva(plazasOcupadas, plazasOcupadasBodega, plazasOcupadasGeneral);
        await this.comprobarPosibleServicioPlazasLibres(servicios, plazasOcupadas, plazasOcupadasBodega, plazasOcupadasGeneral);
        
        if (servicios.every(x => x.srvId !== -1)) {
          this.utils.loading = false;
          const resp = await this.mostrarAvisoLegal();
          if (!resp) {
            this.snackBar.open($localize`Debe aceptar las condiciones para realizar la reserva`, 'OK');
            this.utils.loading = false;
            return;
          }
          try {
            this.utils.loading = true;
            let reserva = new ResReservas(undefined, this.selectPasajerosComponent.correo, undefined, false, this.selectPasajerosComponent.telefono, $event.precio, servicios[environment.srvIda].srvId, servicios[environment.srvVuelta].srvId, plazasOcupadas, plazasOcupadasBodega, plazasOcupadasGeneral, infoSuplementos, '', new Date(), false, undefined, environment.idioma, $event.cupCodigo, this.selectedPaquete.paqId);

            //Solo public
            const updateCapacidadLibrePromises = new Array<Promise<any>>();
            
            servicios.forEach((servicio) => {
              updateCapacidadLibrePromises.push(this.serviciosService.updateCapacidadLibre(servicio.srvId, plazasOcupadas, plazasOcupadasBodega, plazasOcupadasGeneral));
            });
            
            await Promise.all(updateCapacidadLibrePromises)
            .catch((err) => {
              this.utils.loading = false;
              throw new Error($localize`Ha habido un error al modificar el cupo de los servicios` + err);
            });
            //Solo public
  
            await this.reservasService.save(reserva)
            .then((res) => {
              reserva = res;
            })
            .catch((err) => {
              this.utils.loading = false;
              throw new Error($localize`Ha habido un error al crear la reserva` + err);
            });
            
            await this.ticketsService.saveAll(this.selectPasajerosComponent.ticketsTarifas, reserva.rsvId)
            .catch((err) => {
              this.utils.loading = false;
              throw new Error($localize`Ha habido un error al generar los tickets` + err);
            });
            
            if (this.limiteCalendario) {
              const limiteCalendarioUpdated: ResLimiteCalendarios = {...this.limiteCalendario};
              limiteCalendarioUpdated.limCalCapacidadLibre -= plazasOcupadas;
              await this.calendariosService.updateLimiteCalendario(limiteCalendarioUpdated);
            }
            
            await this.reservasService.checkTotalAmount(reserva.rsvId).then(async (valid: boolean) => {
              if (valid) {
                const payment = this.buildPaymentArgs($event, reserva.rsvId.toString());
                await this.paymentsService.pay(payment).catch((err) => {
                  this.utils.loading = false;
                  throw new Error($localize`Ha habido un error al generar la sesión de Stripe` + err);
                });
              } else {
                this.utils.loading = false;
                throw new Error($localize`El coste total de la reserva no es correcto`);
              }
            }).catch((err) => {
              this.utils.loading = false;
              throw new Error($localize`Ha habido un error al comprobar el precio de la reserva` + err);
            });
  
          }
          catch (err){
            this.snackBar.open($localize`Lo sentimos, ha ocurrido un error. Contacte con nosotros por email para poder ofrecerle una solución`, 'OK');
            this.utils.loading = false;
          }
        }
      }
      else if ($event.precio === 0){
        this.snackBar.open($localize`No ha seleccionado ningún ticket`, 'OK');
        this.utils.loading = false;
      } else if (this.selectPasajerosComponent.emailValidation()){
        this.snackBar.open($localize`Debe cumplimentar todos los campos debidamente para realizar la compra`, 'OK');
        this.utils.loading = false;
      } else{
        this.snackBar.open($localize`Los correos no coinciden`, 'OK');
        this.utils.loading = false;
      }
    }
  }
  
  private calculatePlazasOcupadas($event: { precio?: number; selectedTicketsEachTarifa: any; selectedSuplementos: any; }): number {
    const plazasPersonas = $event.selectedTicketsEachTarifa.map((x: { cantidad: any; }) => x.cantidad).reduce((a: any, b: any) => a + b);
    return plazasPersonas;
  }

  private calculatePlazasOcupadasSuplementos($event: any) {
    const suplementosOcupanPlaza = $event.selectedSuplementos.filter((selectedSuplemento: { nombre: string; }) => this.listaSuplementos.filter((suplemento) => suplemento.supOcupaPlaza).some(suplemento => suplemento.supNombre === selectedSuplemento.nombre));
    const plazasOcupadasBodega = suplementosOcupanPlaza.length === 0 ? 0 : this.calculatePlazasOcupadasSuplementosTipo(suplementosOcupanPlaza, BODEGA);
    const plazasOcupadasGeneral = suplementosOcupanPlaza.length === 0 ? 0 : this.calculatePlazasOcupadasSuplementosTipo(suplementosOcupanPlaza, GENERAL);
    return { plazasOcupadasBodega, plazasOcupadasGeneral };
  }
  
  private calculatePlazasOcupadasSuplementosTipo(suplementosOcupanPlaza: any[], tipo: number): number {
    const suplementosFiltradoPorTipo = suplementosOcupanPlaza.filter(x => x.tipo === tipo);
    
    const plazasSuplementos = suplementosFiltradoPorTipo.length === 0 ? 0 : suplementosFiltradoPorTipo.map((x: { cantidad: any; }) => x.cantidad).reduce((a: any, b: any) => a + b);
    return plazasSuplementos;
  }
  
  private getServiciosCreadosForReserva(plazasOcupadas: number, plazasOcupadasBodega: number, plazasOcupadasGeneral: number): ResServicios[] {
    let countSrvIdaPlazasNoAvailable = 0;
    let countSrvIdaBodegaNoAvailable = 0;
    let countSrvIdaGeneralNoAvailable = 0;
    let countSrvVueltaPlazasNoAvailable = 0;
    let countSrvVueltaBodegaNoAvailable = 0;
    let countSrvVueltaGeneralNoAvailable = 0;

    const servicios = new Array<ResServicios>();
    const servicioIda = this.serviciosIda.find((servicio) => {
      const plazasOcupadasOK = servicio.srvCapacidadLibre - plazasOcupadas >= 0;
      const bodegaOcupadasOK = servicio.srvCapacidadBodegaLibre - plazasOcupadasBodega >= 0;
      const generalOcupadasOK = servicio.srvCapacidadGeneralLibre - plazasOcupadasGeneral >= 0;
      
      countSrvIdaPlazasNoAvailable += plazasOcupadasOK ? 0 : 1;
      countSrvIdaBodegaNoAvailable += bodegaOcupadasOK ? 0 : 1;
      countSrvIdaGeneralNoAvailable += generalOcupadasOK ? 0 : 1;

      return plazasOcupadasOK && bodegaOcupadasOK && generalOcupadasOK && servicio.srvHora === this.selectedHorarioIda.horFechaInicio;
    });
    
    const servicioVuelta = this.serviciosVuelta.find((servicio) => {
      const plazasOcupadasOK = servicio.srvCapacidadLibre - plazasOcupadas >= 0;
      const bodegaOcupadasOK = servicio.srvCapacidadBodegaLibre - plazasOcupadasBodega >= 0;
      const generalOcupadasOK = servicio.srvCapacidadGeneralLibre - plazasOcupadasGeneral >= 0;
      
      countSrvVueltaPlazasNoAvailable += plazasOcupadasOK ? 0 : 1;
      countSrvVueltaBodegaNoAvailable += bodegaOcupadasOK ? 0 : 1;
      countSrvVueltaGeneralNoAvailable += generalOcupadasOK ? 0 : 1;
      
      return plazasOcupadasOK && bodegaOcupadasOK && generalOcupadasOK && servicio.srvHora === this.selectedHorarioVuelta.horFechaInicio;
    });
    servicios.push(servicioIda ? servicioIda : new ResServicios(-1));
    servicios.push(servicioVuelta ? servicioVuelta : new ResServicios(-1));
    
    this.srvIdaPlazasNoAvailable = countSrvIdaPlazasNoAvailable === this.serviciosIda.length;
    this.srvIdaBodegaNoAvailable = countSrvIdaBodegaNoAvailable === this.serviciosIda.length;
    this.srvIdaGeneralNoAvailable = countSrvIdaGeneralNoAvailable === this.serviciosIda.length;
    this.srvVueltaPlazasNoAvailable = countSrvVueltaPlazasNoAvailable === this.serviciosVuelta.length;
    this.srvVueltaBodegaNoAvailable = countSrvVueltaBodegaNoAvailable === this.serviciosVuelta.length;
    this.srvVueltaGeneralNoAvailable = countSrvVueltaGeneralNoAvailable === this.serviciosVuelta.length;
    return servicios;
  }
  
  private async comprobarPosibleServicioPlazasLibres(servicios: ResServicios[], plazasOcupadas: number, plazasOcupadasBodega: number, plazasOcupadasGeneral: number): Promise<any> {
    try {
      let promises: Promise<ResServicios>[];
      const selectedFechaOffset = this.selectedFecha;
      selectedFechaOffset.setHours(1);
      while (servicios.some(x => x.srvId === -1)) {
        promises = new Array<Promise<ResServicios>>();
        if (servicios[environment.srvIda].srvId === -1) {
          const horarioIdaExcExistPreviusly = this.excRefuerzos.some(refuerzo => refuerzo.exeTipo === environment.srvIda && refuerzo.excUni.length > 0 && this.horariosIda.map(x => x.horFechaInicio).includes(refuerzo.exeHoraInicio));
          if (this.selectedHorarioIda.horDescripcion !== 'HORARIO UNIDAD REFUERZO' || horarioIdaExcExistPreviusly) {
            promises.push(this.serviciosService.checkUnidadesDisponiblesAndSave(new ResServicios(undefined, selectedFechaOffset, this.selectedHorarioIda.horFechaInicio, undefined, undefined, environment.srvIda, undefined), this.calendarioSelectedPaquete.calId));
          }
          else {
            throw new Error(($localize`Ya han sido ocupadas todas las plazas de refuerzo`));
          }
        }
        if (servicios[environment.srvVuelta].srvId === -1) {
          const horarioVueltaExcExistPreviusly = this.excRefuerzos.some(refuerzo => refuerzo.exeTipo === environment.srvVuelta && refuerzo.excUni.length > 0 && this.horariosVuelta.map(x => x.horFechaInicio).includes(refuerzo.exeHoraInicio));
          if (this.selectedHorarioVuelta.horDescripcion !== 'HORARIO UNIDAD REFUERZO' || horarioVueltaExcExistPreviusly) {
            promises.push(this.serviciosService.checkUnidadesDisponiblesAndSave(new ResServicios(undefined, selectedFechaOffset, this.selectedHorarioVuelta.horFechaInicio, undefined, undefined, environment.srvVuelta, undefined), this.calendarioSelectedPaquete.calId));
          }
          else {
            throw new Error(($localize`Ya han sido ocupadas todas las plazas de refuerzo`));
          }
        }
        if (promises.length > 0) {
          const resServicios = await Promise.all(promises);
          resServicios.forEach(resServicio => {
            if (resServicio.srvTipo === environment.srvIda) {
              this.serviciosIda.push(resServicio);
            }
            if (resServicio.srvTipo === environment.srvVuelta) {
              this.serviciosVuelta.push(resServicio);
            }
          });
          this.applyRestriccionesUnidades();
          const serviciosCreados = this.getServiciosCreadosForReserva(plazasOcupadas, plazasOcupadasBodega, plazasOcupadasGeneral);
          servicios[environment.srvIda] = serviciosCreados[environment.srvIda]
          servicios[environment.srvVuelta] = serviciosCreados[environment.srvVuelta]
        }
      }
    } catch (err) {
      this.utils.loading = false;
      if (this.srvIdaPlazasNoAvailable || this.srvIdaBodegaNoAvailable || this.srvIdaGeneralNoAvailable || this.srvVueltaPlazasNoAvailable || this.srvVueltaBodegaNoAvailable || this.srvVueltaGeneralNoAvailable) {
        let msg = $localize`No se ha podido realizar la reserva para esta fecha y horas seleccionadas por los siguientes motivos: `;

        if (this.srvIdaPlazasNoAvailable) {
          msg += '\n\n' + $localize`No hay capacidad suficiente en el servicio de ida para los pasajeros`;
          if (this.srvIdaBodegaNoAvailable || this.srvIdaGeneralNoAvailable) {
            msg += $localize` y equipaje extra`;
          }
          msg += $localize` seleccionados.`;
        } else {
          if (this.srvIdaBodegaNoAvailable || this.srvIdaGeneralNoAvailable) {
            msg += '\n\n' + $localize`No hay capacidad suficiente en el servicio de ida para los suplementos seleccionados.`;
          }
        }

        if (this.srvVueltaPlazasNoAvailable) {
          msg += '\n\n' + $localize`No hay capacidad suficiente en el servicio de vuelta para los pasajeros`;
          if (this.srvVueltaBodegaNoAvailable || this.srvVueltaGeneralNoAvailable) {
            msg += $localize` y equipaje extra`;
          }
          msg += $localize` seleccionados.`;
        } else {
          if (this.srvVueltaBodegaNoAvailable || this.srvVueltaGeneralNoAvailable) {
            msg += '\n\n' + $localize`No hay capacidad suficiente en el servicio de vuelta para los suplementos seleccionados.`;
          }
        }
        this.snackBar.open(msg, 'OK', { duration: 10000, panelClass: 'noplaza-snackbar'});
      } else {
        this.snackBar.open(err, 'OK', { duration: 10000 });
      }
    }
  }
  
  // --------------------------------- Funciones auxiliares ---------------------------------------- //
  
  private compareMiliseconds(x: ResHorario, anticipacionMS: number) {
    const hourInfo = x.horFechaInicio.split(':').map(x => parseInt(x, 10));
    return anticipacionMS < new Date(1970, 1, 1, hourInfo[0], hourInfo[1]).getTime();
  }

  private fillAvailableHorarios(anticipacionMS: number, daysAnticipacion: number, currentDaysAnticipacion: number) {
    if (this.allHorariosIda.length > 0 && this.allHorariosVuelta.length > 0) {
      this.availableHorariosIda = [...this.horariosIda];
      this.availableHorariosVuelta = [...this.horariosVuelta];
      this.filterAvailableCalendar();
      this.getDailyExcepciones();
      if (daysAnticipacion === currentDaysAnticipacion) {
        this.availableHorariosIda = this.availableHorariosIda.filter((x) => this.compareMiliseconds(x, anticipacionMS));
        this.availableHorariosVuelta = this.availableHorariosVuelta.filter((x) => this.compareMiliseconds(x, anticipacionMS));
      }
    }
  }
  
  toogleSelectAutorizacion($event: boolean): void {
    this.autorizacionChecked = true;
    this.quiereGestionAutorizacion = $event;
    this.radioValue = this.quiereGestionAutorizacion ? 1 : 2;
    if (this.resumenPagoComponent){
      this.resumenPagoComponent.setGastosGestion(this.quiereGestionAutorizacion);
    }
  }

  filterVueltaGreaterThanIda(): void {
    const horIdaUnix = this.utils.hourToUnixTime(this.selectedHorarioIda.horFechaInicio);
    this.availableHorariosVuelta = [...this.allHorariosVuelta];
    this.availableHorariosVuelta = this.availableHorariosVuelta.filter((horario) => this.utils.hourToUnixTime(horario.horFechaInicio) > horIdaUnix);
    this.selectedHorarioVuelta = this.availableHorariosVuelta.some(x => x === this.selectedHorarioVuelta) ? this.selectedHorarioVuelta : undefined;
  }

  filterIdaGreatherThanCurrentHour(): void {
    const currentHour = this.utils.hourToUnixTime();
    this.availableHorariosIda = [...this.allHorariosIda];
    this.availableHorariosIda = this.availableHorariosIda.filter((horario) => this.utils.hourToUnixTime(horario.horFechaInicio) > currentHour);
    this.selectedHorarioIda = this.availableHorariosIda.some(x => x === this.selectedHorarioIda) ? this.selectedHorarioIda : undefined;
  }

  clearInfo(): void{
    this.calendarioSelectedPaquete = undefined;
    this.selectedFecha = undefined;
    this.allHorariosIda = [];
    this.allHorariosVuelta = [];
    this.horariosIda = [];
    this.horariosVuelta = [];
    this.availableHorariosIda = [];
    this.availableHorariosVuelta = [];
    this.selectedHorarioIda = undefined;
    this.selectedHorarioVuelta = undefined;
    this.autorizacionChecked = false;
    this.limitReached = false;
    this.excRestricciones = []; 
    this.excRefuerzos = []; 
    this.radioValue = 0;
  }

  onResize(event: { target: { innerWidth: number; }; }): void {
    this.colSize = (event.target.innerWidth <= 900) ? (event.target.innerWidth <= 550) ? 1 : 2 : 4;
  }

  private calendarioIsInRange(): boolean {
    return this.calendarioSelectedPaquete && new Date(this.calendarioSelectedPaquete.calFechaInicio) <= this.selectedFecha && new Date(this.calendarioSelectedPaquete.calFechaFin) >= this.selectedFecha;
  }

  private buildPaymentArgs($event: { precio: number; selectedTicketsEachTarifa: { nombre: string; precio: number; cantidad: number; }[]; }, reservaId: string): any {
    return {
      name: this.selectedPaquete.paqDescripcion + ': ' + this.datePipe.transform(this.selectedFecha, 'dd/MM/yyyy') + ' ' + this.selectedHorarioIda.horFechaInicio + '-' + this.selectedHorarioVuelta.horFechaInicio,
      currency: 'eur',
      amount: $event.precio * 100,
      quantity: '1',
      successUrl: environment.ui_host + 'success',
      cancelUrl: environment.ui_host + 'issue',
      email: this.selectPasajerosComponent.correo,
      rsvId: reservaId
    };
  }

  private hourIsGreatherThanHour(horario1: string, horario2: string): number {
    const date1 = this.utils.hourToUnixTime(horario1);
    const date2 = this.utils.hourToUnixTime(horario2);
    return date1 >= date2 ? 1 : -1;
  }

  private onlyUnique(value: { horId: any; }, index: any, self: any[]): any {
    return self.findIndex((x: { horId: any; }) => value.horId === x.horId) === index;
  }

  private mostrarAvisoLegal(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.dialog.open(MensajeLegalComponent, {maxWidth: '600px', width: '80%' }).afterClosed().subscribe((respuesta: boolean) => {
        resolve(respuesta);
      });
    });
  }
}
