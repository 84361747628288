import { ResExcepcion } from './../models/response/res_excepcion';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResServicios } from '../models/response/res_servicios';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(private http: HttpClient,
              private datePipe: DatePipe) { }
    private URL_SERVICIOS = 'servicio';

    public getAll(): Promise<Array<ResServicios>> {
      return new Promise<Array<ResServicios>>((resolve, reject) => {
        this.http.get(environment.backend_public + this.URL_SERVICIOS + '/getAll')
      .subscribe((res: any) => {
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }

  public get(idServicio: number): Promise<ResServicios> {
    return new Promise<ResServicios>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_SERVICIOS + '/get/' + idServicio)
      .subscribe((res: any) => {
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }

  public save(servicio: ResServicios): Promise<ResServicios> {
    return new Promise<ResServicios>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_SERVICIOS + '/save/', servicio)
      .subscribe((res: any) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }

  updateCapacidadLibre(srvId: number, plazasOcupadas: number, bodegaOcupadas: number, generalOcupadas: number): Promise<any> {
    return new Promise<ResServicios>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_SERVICIOS + '/updateCapacidadLibre/', {srvId, plazasOcupadas, bodegaOcupadas, generalOcupadas})
      .subscribe((res: ResServicios) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }

  public checkUnidadesDisponiblesAndSave(servicio: ResServicios, calId: number): Promise<any> {
    const horaSTR = servicio.srvHora;
    servicio.srvHora = undefined;
    return new Promise<ResServicios>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_SERVICIOS + '/checkUnidadesDisponiblesAndSave?calId=' + calId + '&srvHoraDate=' + horaSTR, servicio)
      .subscribe((res: ResServicios) => {
        resolve(res);
      },
      (error: any) => {
        reject($localize`Lo sentimos, no quedan plazas para este día`);
      });
    });
  }

  public getServicioByCalendarioFechaTipo(calendario: number, fecha: string, hora: string, tipo: number): Promise<ResServicios[]> {
    return new Promise<ResServicios[]>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_SERVICIOS + '/getServicioByCalendarioFechaTipo/', {calendario, fecha, hora, tipo})
      .subscribe((res: ResServicios[]) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }

  checkRefuerzosAndSaveAll(refuerzos: ResExcepcion[], fechaSeleccionada: Date, nombrePaquete: string): Promise<ResServicios[]> {
    return new Promise<ResServicios[]>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_SERVICIOS + '/checkRefuerzosAndSaveAll/', {refuerzos, fechaSeleccionada, nombrePaquete})
      .subscribe((res: ResServicios[]) => {
        if (res.length > 0) {
          res.map((servicio) => {
            const fechaSTR = servicio.srvFecha.toString();
            const fechaSTRSplit = fechaSTR.split('-').map(x => parseInt(x, 10));
            servicio.srvFecha = new Date(fechaSTRSplit[0], fechaSTRSplit[1], fechaSTRSplit[2]);
            return servicio;
          });
        }
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }
}
