import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { loadStripe, Stripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient
  ) { }
  stripePromise = loadStripe(environment.stripe_pk);

  async pay(paymentInfo: any): Promise<any> {
    const stripe: any = await this.stripePromise;
    this.http.post(environment.backend_payments + 'pay/payment', paymentInfo)
      .subscribe((data: any) => {
        stripe.redirectToCheckout({
          sessionId: data.id,
        });
      });
  }
}
