import { TicketsService } from './../../../services/tickets.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {
  id: any;

  constructor(private router: Router,
              private ticketsService: TicketsService,
              private activatedRoute: ActivatedRoute) { }


  btnClick(): void {
    this.router.navigate(['']);
  }
}
