import { ResExcepcion } from './../models/response/res_excepcion';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResCalendario } from '../models/response/res_calendario';

@Injectable({
  providedIn: 'root'
})
export class ExcepcionesService {

  private URL_EXCEPCIONES = 'excepcion';

  constructor(private http: HttpClient) { }

  public getAllExcepciones(): Promise<Array<ResExcepcion>> {
    return new Promise<Array<ResExcepcion>>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_EXCEPCIONES + '/getAll')
      .subscribe((res: Array<ResExcepcion>) => {
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }

  public getExcepcionesByDate(fechaSeleccionada: string, idCalendario: number): Promise<Array<ResExcepcion>> {
    return new Promise<Array<ResExcepcion>>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_EXCEPCIONES + '/getExcepcionesByDate', { fechaSeleccionada, idCalendario })
      .subscribe((res: Array<ResExcepcion>) => {
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }
}
