import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResCalendario } from '../models/response/res_calendario';
import { ResLimiteCalendarios } from '../models/response/res_limiteCalendarios';

@Injectable({
  providedIn: 'root'
})
export class CalendariosService {

  private URL_CALENDARIOS = 'calendario';

  constructor(private http: HttpClient) { }

  public getAllCalendarios(): Promise<Array<ResCalendario>> {
    return new Promise<Array<ResCalendario>>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_CALENDARIOS + '/getAll')
      .subscribe((res: Array<ResCalendario>) => {
        resolve(res.map((x: ResCalendario) => new ResCalendario(x.calId, x.calDescripcion, new Date(x.calFechaInicio), new Date(x.calFechaFin), x.calDias, x.calPaqId, x.planningList, x.calCapacidad)));
      },
      (error: any) => {
        reject();
      });
    });
  }

  public getCalendarioByPaqueteIdFecha(idPaquete: number, fecha: string): Promise<ResCalendario> {
    return new Promise<ResCalendario>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_CALENDARIOS + '/getCalendarioByPaqueteIdFecha/' + idPaquete + '/' + fecha)
      .subscribe((res: ResCalendario) => {
        resolve(res);
      },
      (error: any) => {
        if (error.status === 404) {
          reject($localize`No hay disponibilidad para esta fecha/hora. Por favor, seleccione otra.`);
        } else {
          reject($localize`El servicio actualmente no está disponible, inténtelo más tarde.`);
        }
      });
    });
  }
  getLimiteCalendarioByIdCalendarioAndFecha(id: number, fecha: string): Promise<ResLimiteCalendarios> {
    return new Promise<ResLimiteCalendarios>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_CALENDARIOS + '/getLimiteCalendarioByIdCalendarioAndFecha/' + id + '/' + fecha)
      .subscribe((res: ResLimiteCalendarios) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }

  updateLimiteCalendario(limiteCalendario: ResLimiteCalendarios): Promise<ResLimiteCalendarios> {
    return new Promise<ResLimiteCalendarios>((resolve, reject) => {
      this.http.put(environment.backend_public + this.URL_CALENDARIOS + '/updateLimiteCalendario/', limiteCalendario)
      .subscribe((res: ResLimiteCalendarios) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }
}
