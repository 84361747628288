export class Paquete {

  paqId: number;
  paqNombre: string;
  paqDescripcion: string;
  paqActivo: boolean;
  paqRequiereAutorizacion: boolean;
  paqAutorizacionMensaje: string;
  paqAutorizacionSi: string;
  paqAutorizacionNo: string;
  paqOrden: number;
  paqLocalizador: string;
  paqTradCodigo: string;
  paqPrivado: boolean;

  constructor($paqId: number, $paqNombre: string, $paqDescription: string, $paqActivo: boolean, $paqRequiereAutorizacion: boolean, $paqAutorizacionMensaje: string,
      $paqAutorizacionSi: string, $paqAutorizacionNo: string, $paqOrden: number, $paqLocalizador: string, $paqTradCodigo: string, $paqPrivado: boolean) {
      this.paqActivo = $paqActivo;
      this.paqNombre = $paqNombre;
      this.paqDescripcion = $paqDescription;
      this.paqId = $paqId;
      this.paqRequiereAutorizacion = $paqRequiereAutorizacion;
      this.paqAutorizacionMensaje = $paqAutorizacionMensaje;
      this.paqAutorizacionSi = $paqAutorizacionSi;
      this.paqAutorizacionNo = $paqAutorizacionNo;
      this.paqOrden = $paqOrden;
      this.paqLocalizador = $paqLocalizador;
      this.paqTradCodigo = $paqTradCodigo;
      this.paqPrivado = $paqPrivado;
  }
}
