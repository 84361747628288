import { environment } from './../../environments/environment';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResPaquete } from '../models/response/res_paquete';

@Injectable({
  providedIn: 'root'
})
export class PaquetesService {

  private URL_PAQUETES = 'paquete';

  constructor(private http: HttpClient) { }

  public getAllPaquetes(): Promise<Array<ResPaquete>> {
    return new Promise<Array<ResPaquete>>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_PAQUETES + '/getAll')
      .subscribe((res: Array<ResPaquete>) => {
        res = res.sort((a, b) => a.paqOrden - b.paqOrden);
        res = res.map(paquete => {
          paquete.tarifas = paquete.tarifas.sort((a, b) => a.tarOrden - b.tarOrden);
          return paquete;
        });
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }

  public getPaquete(idPaquete: number): Promise<ResPaquete> {
    return new Promise<ResPaquete>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_PAQUETES + '/get/' + idPaquete)
      .subscribe((res: ResPaquete) => {
        res.tarifas = res.tarifas.sort((a, b) => a.tarOrden - b.tarOrden);
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }
}
