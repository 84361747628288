import { ResReservas } from './res_reservas';
export class ResServicios {
  srvId: number;
  srvFecha: Date;
  srvHora: string;
  srvHoraDATE: Date;
  srvUnidad: string;
  srvCapacidad: number;
  srvCapacidadLibre: number;
  srvCapacidadBodega: number;
  srvCapacidadBodegaLibre: number;
  srvCapacidadGeneral: number;
  srvCapacidadGeneralLibre: number;
  srvTipo: number;
  reservasInicio: ResReservas[];
  reservasFin: ResReservas[];

  constructor(srvId?: number, srvFecha?: Date, srvHora?: string, srvUnidad?: string, srvCapacidad?: number, srvTipo?: number, srvCapacidadLibre?: number, $srvCapacidadBodega?: number, $srvCapacidadBodegaLibre?: number, $srvCapacidadGeneral?: number, $srvCapacidadGeneralLibre?: number) {
    this.srvId = srvId;
    this.srvFecha = srvFecha;
    this.srvHora = srvHora;
    this.srvUnidad = srvUnidad;
    this.srvCapacidad = srvCapacidad;
    this.srvCapacidadLibre = srvCapacidadLibre;
    this.srvCapacidadBodega = $srvCapacidadBodega;
    this.srvCapacidadBodegaLibre = $srvCapacidadBodegaLibre;
    this.srvCapacidadGeneral = $srvCapacidadGeneral;
    this.srvCapacidadGeneralLibre = $srvCapacidadGeneralLibre;
    this.srvTipo = srvTipo;
  }
}
