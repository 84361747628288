import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const httpRequest = req.clone({
        headers: new HttpHeaders({
            'Content-Type': 'application/json', 
            'Idioma': environment.idioma
        })
    });
    return next.handle(httpRequest);
  }
}