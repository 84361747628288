export const environment = {
  production: true,
  ui_host: 'https://desfront-nortour.tecnicascompetitivas.com/#/',
  backend_public: 'https://desgw-nortour.tecnicascompetitivas.com/nortour-api-public/',
  backend_privated: 'https://desgw-nortour.tecnicascompetitivas.com/nortour-api/',
  backend_payments: 'https://desgw-nortour.tecnicascompetitivas.com/nortour-payments/',
  srvIda: 0,
  srvVuelta: 1,
  demoMode: false,
  idAutorizacion: 7,
  autorizacionNombre: 'Gestión de Autorización',
  stripe_pk: 'pk_test_51IvemuGdD2GmjMjVokezRHK6gcC87kmqlahEyv5LFi7GyXtzOEt0A2L17XlTgrYRVQJsBg8kh8cGJTCP8FJYyEvL00CF35ESl8',
  idioma: 'es-ES',
  privacidad_url: 'https://navieranortour.com/privacidad/',
  condiciones_url: 'https://navieranortour.com/terminos-transporte/'
};
