import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mensaje-legal',
  templateUrl: './mensaje-legal.component.html',
  styleUrls: ['./mensaje-legal.component.scss']
})
export class MensajeLegalComponent implements OnInit {

  privacidad_url = 'https://navieranortour.com/privacidad/';
  condiciones_url = 'https://navieranortour.com/terminos-transporte/';
  respuestaPrivacidad = false;
  respuestaCodiciones = false;

  constructor(@Optional() private dialogRef: MatDialogRef<MensajeLegalComponent>) { }

  ngOnInit(): void {
    this.privacidad_url = environment.privacidad_url;
    this.condiciones_url = environment.condiciones_url;
  }

  continuar(): void {
    this.dialogRef.close(this.respuestaPrivacidad && this.respuestaCodiciones);
  }

}
