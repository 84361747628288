import { ResActividad } from './res_actividad';
import { ResTarifa } from './res_tarifa';
export class ResPaquete {

    paqId: number;
    paqNombre: string;
    paqDescripcion: string;
    paqActivo: boolean;
    paqRequiereAutorizacion: boolean;
    paqAutorizacionMensaje: string;
    paqAutorizacionSi: string;
    paqAutorizacionNo: string;
    paqOrden: number;
    paqLocalizador: string;
    paqTradCodigo: string;
    paqPrivado: boolean;
    paqAnticipacionCompra: number;
    tarifas: ResTarifa[];
    actividades: ResActividad[];

    constructor($paqId: number, $paqNombre: string, $paqDescripcion: string, $paqActivo: boolean, $paqRequiereAutorizacion: boolean, $paqAutorizacionMensaje: string,
        $paqAutorizacionSi: string, $paqAutorizacionNo: string, $paqOrden: number, $paqLocalizador: string, $paqTradCodigo: string, $paqPrivado: boolean, $paqAnticipacionCompra: number,
        $tarifas: ResTarifa[], $actividades: ResActividad[]){
        this.paqActivo = $paqActivo;
        this.paqNombre = $paqNombre;
        this.paqDescripcion = $paqDescripcion;
        this.paqId = $paqId;
        this.paqRequiereAutorizacion = $paqRequiereAutorizacion;
        this.paqAutorizacionMensaje = $paqAutorizacionMensaje;
        this.paqAutorizacionSi = $paqAutorizacionSi;
        this.paqAutorizacionNo = $paqAutorizacionNo;
        this.paqOrden = $paqOrden;
        this.paqLocalizador = $paqLocalizador;
        this.paqTradCodigo = $paqTradCodigo;
        this.paqPrivado = $paqPrivado;
        this.paqAnticipacionCompra = $paqAnticipacionCompra;
        this.tarifas = $tarifas;
        this.actividades = $actividades;
    }
}
