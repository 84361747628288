import { ResReservas } from './../models/response/res_reservas';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservasService {

  constructor(private http: HttpClient) { }
  private URL_RESERVAS = 'reserva';

  public save(reserva: ResReservas): Promise<ResReservas> {
    reserva.generateLocalizador();
    return new Promise<ResReservas>((resolve, reject) => {
      this.http.post(environment.backend_public + this.URL_RESERVAS + '/save/', reserva)
      .subscribe((res: any) => {
        resolve(res);
      },
      (error: any) => {
        reject(error);
      });
    });
  }

  public checkTotalAmount(rsvId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_RESERVAS + '/checkTotalPrice/' + rsvId).subscribe((res: boolean) => {
        resolve(res);
      }, (error: any) => {
        reject(error);
      });
    });
  }
}
