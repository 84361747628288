import { ResSuplemento } from './../models/response/res_suplemento';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuplementosService {

  private URL_SUPLEMENTOS = 'suplemento';

  constructor(private http: HttpClient) { }

  public getAllSuplementos(): Promise<Array<ResSuplemento>> {
    return new Promise<Array<ResSuplemento>>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_SUPLEMENTOS + '/getAll')
      .subscribe((res: Array<ResSuplemento>) => {
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }

  public getByIdPaquete(idPaquete: number): Promise<Array<ResSuplemento>> {
    return new Promise<Array<ResSuplemento>>((resolve, reject) => {
      this.http.get(environment.backend_public + this.URL_SUPLEMENTOS + '/getByIdPaquete/' + idPaquete)
      .subscribe((res: Array<ResSuplemento>) => {
        resolve(res);
      },
      (error: any) => {
        reject();
      });
    });
  }
}
