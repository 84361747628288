import { environment } from './../environments/environment';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { Subscription } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'NortourFront';
  lang: string;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  private cookieValue: string;

  constructor(
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    public utils: UtilsService,
    ) {
  }

  ngOnInit() {
    this.lang = environment.idioma;

    this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        
        this.ccService.getConfig().content.header = '';
        this.ccService.getConfig().content.message = $localize `Utilizamos cookies, tanto propias como de terceros, para garantizar el buen funcionamiento de nuestra página web. Al pulsar en "Continuar" consiente la instalación de estas cookies.`;
        this.ccService.getConfig().content.dismiss = '';
        this.ccService.getConfig().content.allow = $localize `Continuar`;
        this.ccService.getConfig().content.deny = $localize `Rechazar`;
        this.ccService.getConfig().content.link = $localize `https://navieranortour.com/cookies/`;
        this.ccService.getConfig().content.href = $localize `https://navieranortour.com/cookies/`;
        this.ccService.getConfig().content.policy = $localize `Configurar cookies`;
 
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());

    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      //console.log('popupOpenSubscription');
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      //console.log('popupCloseSubscription');
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        //console.log('initializeSubscription');
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        //console.log('statusChangeSubscription');

        //TODO: CANCELAR LAS COOKIES
        this.deleteAllCookies();
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        //console.log('revokeChoiceSubscription');
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        //console.log('noCookieLawSubscription');
      }
    );
  }

  deleteAllCookies() {

    var cookies = window.document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      //console.log(cookies[i]);
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        //console.log('eqPos=' + eqPos);
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //console.log('name=' + name);
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

  }

  toogleNavBar(): void {
    document.getElementById('navbar').classList.toggle('show');
  }

  toogleDropdown(): void {
    document.getElementById('dropdown').classList.toggle('show');
  }

  handleClickSound() {
    let x = <HTMLVideoElement>document.getElementById("myAudio");
    x.play();
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}
