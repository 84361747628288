import { Time } from '@angular/common';
import { ResPlanning } from './res_planning';
import { ResUnidad } from './res_unidad';

export class ResHorario {

    horId: number;
    horDescripcion: string;
    tipo: number;
    horFechaInicio: string;
    horFechaFin: string;
    planningList: Array<ResPlanning>;

    constructor($horId: number, $horDescripcion: string, $horFechaInicio: string,
                $horFechaFin: string, $tipo: number, $planningList: Array<ResPlanning>) {
        this.horId = $horId;
        this.horDescripcion = $horDescripcion;
        this.horFechaInicio = $horFechaInicio;
        this.horFechaFin = $horFechaFin;
        this.tipo = $tipo;
        this.planningList = $planningList;
    }
}

// export class CTime implements Time {
//   hours: number;
//   minutes: number;
//   seconds: number;
// }
